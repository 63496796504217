<template>
  <div class="er-comp">
    <v-container fluid>
      <v-row dense no-gutters>
        <v-col cols="12">
      <v-card tile class="rating-wrapper border mx-auto">
        <v-card-title class="">Teacher Practice Score</v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row style="margin-left:6px; margin-top: 10px;margin-right:6px;">
                <v-col cols="12" class="" style="">
                  <div class="sub-text">Practice is measured by performance on
                    a teacher practice instrument, which is used to gather evidence
                     primarily through classroom observations and pre/post-conferences.
                      Districts have the flexibility
                      to choose from a growing list of state-approved instruments.
                  </div>
                </v-col>
              </v-row>
              <v-row style="margin-left:6px; margin-top: 10px;margin-right:6px;">
                <v-col cols="6" class="" style="">
                  <div class="rating-count-wrapper">
                    <div class="sm Highly">Highly Effective: {{ practiceScore.count[0] }} </div>
                    <div class="sm Effective">Effective: {{ practiceScore.count[1] }} </div>
                    <div class="sm Partially">
                        Partially Effective: {{ practiceScore.count[2] }}
                    </div>
                    <div class="sm Ineffective">Ineffective: {{ practiceScore.count[3] }} </div>
                    <div class="sm NE">NE: {{ practiceScore.count[4] }} </div>
                  </div>
                </v-col>
                <v-col cols="6" class="" style="">
                  <div v-if="hasData">
                    <bubble-chart aria-hidden="true" :percent="practiceScoreData"
                    class="bubble-chart-wrapper">
                    </bubble-chart>
                    <table class="sr-only">
                      <thead>
                        <tr>
                          <th>Label</th>
                          <th>Count</th>
                          <th>Percent</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(r, i) in practiceScoreData.labels" :key="r">
                            <td>{{ r }}</td>
                            <td>{{ practiceScoreData.count[i] }}</td>
                            <td>{{ practiceScoreData.percent[i] }}%</td>
                          </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-else style="text-align:center;width: 100%;">
                    No Data to Display at this Time.
                  </div>
                </v-col>
              </v-row>
            </v-container>
        </v-card-text>
      </v-card>
    </v-col>
    </v-row>
    </v-container>
  </div>
</template>

<script>
import BubbleChart from '../BubbleChart.vue';

export default {
  name: 'ERTeacherPracticeScoreComponent',
  components: { BubbleChart },
  props: { provider: {}, program: {}, year: {} },
  computed: {
    practiceScore() {
      return this.$store.getters.practiceScore(this.provider, this.program, this.year);
    },
    practiceScoreData() {
      const r = this.practiceScore;
      const newPracticeScore = { labels: r.labels };
      newPracticeScore.percent = r.percent.map((v) => ((typeof v === 'number') ? v : 0));
      newPracticeScore.count = r.count.map((v) => ((typeof v === 'number') ? v : 0));
      return newPracticeScore;
    },
    hasData() {
      return this.practiceScoreData.count.filter((v) => v !== 0).length > 0;
    },
  },
};
</script>

<style scoped>
  .border{
      border: solid 1px;
      margin: 10px;
  }
  .rating-wrapper{
    /* height:100%; */
  }
  .sub-text{
    line-height: 1.2;
  }
  .v-card__title{
    font-size: 15px;
    padding-top: 5px;
  }
  .v-card__text{
    padding: 2px;
  }

</style>
