<template>
  <div class="er-comp">
    <v-container fluid>
      <v-row dense no-gutters>
        <v-col cols="12">
      <v-card tile class="rating-wrapper border mx-auto">
          <v-card-title class="">Teacher SGO Score</v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row style="margin-left:6px; margin-top: 10px;margin-right:6px;">
                <v-col cols="12" class="" style="">
                  <div class="sub-text">The combined score for a teacher’s&nbsp;
                    Student Growth Objectives as assessed by the district’s&nbsp;
                    evaluation system for assigning teacher or principal performance ratings.
                  </div>
                </v-col>
              </v-row>
              <v-row style="margin-left:6px; margin-top: 10px;margin-right:6px;">
                <v-col cols="6" class="" style="">
                  <div class="rating-count-wrapper">
                    <div class="sm Highly">Highly Effective: {{ sgoScore.count[0] }} </div>
                    <div class="sm Effective">Effective: {{ sgoScore.count[1] }} </div>
                    <div class="sm Partially">Partially Effective: {{ sgoScore.count[2] }} </div>
                    <div class="sm Ineffective">Ineffective: {{ sgoScore.count[3] }} </div>
                    <div class="sm NE">NE: {{ sgoScore.count[4] }} </div>
                  </div>
                </v-col>
                <v-col cols="6" class="" style="">
                  <div v-if="hasData">
                    <bubble-chart aria-hidden="true"
                      :percent="sgoScoreData" class="bubble-chart-wrapper">
                    </bubble-chart>
                    <table class="sr-only">
                      <thead>
                        <tr>
                          <th>Label</th>
                          <th>Count</th>
                          <th>Percent</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(r, i) in sgoScoreData.labels" :key="r">
                          <td>{{ r }}</td>
                          <td>{{ sgoScoreData.count[i] }}</td>
                          <td>{{ sgoScoreData.percent[i] }}%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-else style="text-align:center;width: 100%;">
                    No Data to Display at this Time.
                  </div>
                </v-col>
              </v-row>
            </v-container>
        </v-card-text>
      </v-card>
    </v-col>
    </v-row>
    </v-container>
  </div>
</template>

<script>

import BubbleChart from '../BubbleChart.vue';

export default {
  name: 'ERTeacherSGOScoreComponent',
  components: { BubbleChart },
  props: { provider: {}, program: {}, year: {} },
  computed: {
    sgoScore() {
      return this.$store.getters.sgoScore(this.provider, this.program, this.year);
    },
    sgoScoreData() {
      const r = this.sgoScore;
      const newSgoScore = { labels: r.labels };
      newSgoScore.percent = r.percent.map((v) => ((typeof v === 'number') ? v : 0));
      newSgoScore.count = r.count.map((v) => ((typeof v === 'number') ? v : 0));
      return newSgoScore;
    },
    hasData() {
      return this.sgoScoreData.count.filter((v) => v !== 0).length > 0;
    },
  },
};
</script>

<style scoped>
  .border{
      border: solid 1px;
      margin: 10px;
  }
  .rating-wrapper{
    /* height:100%; */
  }
  .sub-text{
    line-height: 1.2;
  }
  .v-card__title{
    font-size: 15px;
    padding-top: 5px;
  }
  .v-card__text{
    padding: 2px;
  }

</style>
