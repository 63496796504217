var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-flex',[(_vm.genders !== null)?_c('v-card',{attrs:{"tile":"","flat":"","max-width":"100%"}},[_c('v-card-title',{staticClass:"headline cd-headline"},[_vm._v("Completers by Gender:")]),_c('v-card-text',[_c('div',{staticClass:"gender"},[_vm._l((_vm.female),function(f){return _c('span',{key:f.Subgroup,class:f.Subgroup,style:({width: _vm.width(f.Percentage) + '%',
                  maxWidth: _vm.maxWidth(f.Percentage), minWidth: _vm.minWidth(f.Percentage)}),attrs:{"title":"Female"}},[_vm._v(" Female "+_vm._s(_vm.percentOrBlank(f.Percentage))+"%")])}),_vm._l((_vm.male),function(m){return _c('span',{key:m.Subgroup,class:m.Subgroup,style:({width: _vm.width(m.Percentage) + '%',
                  maxWidth: _vm.maxWidth(m.Percentage), minWidth: _vm.minWidth(m.Percentage)}),attrs:{"title":"Male"}},[_vm._v(" Male "+_vm._s(_vm.percentOrBlank(m.Percentage))+"%")])}),_vm._l((_vm.nonbinary),function(x){return _c('span',{key:x.Subgroup,class:x.Subgroup +' X',style:({display: 'inline-block',
                  width: _vm.width(x.Percentage) + '%',
                  maxWidth: _vm.maxWidth(x.Percentage), minWidth: _vm.minWidth(x.Percentage)}),attrs:{"title":"Non-Binary/Undesignated"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"flex":"1"}},[_c('span',[_vm._v("Non Binary/ "),_c('br'),_vm._v("Undesignated")])]),_c('div',{staticStyle:{"flex":"1","padding-top":"10px","padding-left":"10px"}},[_c('span',[_vm._v(" "+_vm._s(_vm.percentOrBlank(x.Percentage))+"% ")])])])])}),_vm._l((_vm.not),function(n){return _c('span',{key:n.Subgroup,class:n.Subgroup +' N',style:({display: 'inline-block',
                  width: _vm.width(n.Percentage) + '%',
                  maxWidth: _vm.maxWidth(n.Percentage), minWidth: _vm.minWidth(n.Percentage)}),attrs:{"title":"Gender Not Reported"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"flex":"1"}},[_c('span',[_vm._v("Unreported/ "),_c('br'),_vm._v("Unknown")])]),_c('div',{staticStyle:{"flex":"1","padding-top":"10px","padding-left":"10px"}},[_c('span',[_vm._v(" "+_vm._s(_vm.percentOrBlank(n.Percentage))+"% ")])])])])})],2)])],1):_vm._e(),_c('v-simple-table',{staticClass:"d-none d-sr-only",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Gender Sub Group")]),_c('th',[_vm._v("Percentage")])])]),_c('tbody',[_vm._l((_vm.female),function(f){return _c('tr',{key:f.Subgroup},[_c('td',[_vm._v("Female")]),_c('td',[_vm._v(_vm._s(_vm.percentOrBlank(f.Percentage))+"%")])])}),_vm._l((_vm.male),function(m){return _c('tr',{key:m.Subgroup},[_c('td',[_vm._v("Male")]),_c('td',[_vm._v(_vm._s(_vm.percentOrBlank(m.Percentage))+"%")])])}),_vm._l((_vm.nonbinary),function(x){return _c('tr',{key:x.Subgroup},[_c('td',[_vm._v("Non-Binary/Undesignated")]),_c('td',[_vm._v(_vm._s(_vm.percentOrBlank(x.Percentage))+"%")])])}),_vm._l((_vm.not),function(n){return _c('tr',{key:n.Subgroup},[_c('td',[_vm._v("Gender Not Reported")]),_c('td',[_vm._v(_vm._s(_vm.percentOrBlank(n.Percentage))+"%")])])})],2)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }