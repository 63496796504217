<template>
  <div class="er-comp">
    <v-container fluid>
      <v-row dense no-gutters>
        <v-col cols="12">
      <v-card tile class="rating-wrapper border mx-auto">
          <v-card-title class="">Summative Score</v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row style="margin-left:6px; margin-top: 10px;margin-right:6px;">
                <v-col cols="12" class="" style="">
                  <div class="sub-text">Summative scores are based on multiple measures of student
                    achievement and teacher practice. While all teachers receive an annual summative
                      evaluation, the components used to determine the summative score vary
                      depending on the grades and subjects that educators teach.
                  </div>
                </v-col>
              </v-row>
              <v-row style="margin-left:6px; margin-top: 10px;margin-right:6px;">
                <v-col cols="6" class="" style="">
                  <div class="rating-count-wrapper">
                    <div class="sm Highly">Highly Effective: {{ rating.count[0] }} </div>
                    <div class="sm Effective">Effective: {{ rating.count[1] }} </div>
                    <div class="sm Partially">Partially Effective: {{ rating.count[2] }} </div>
                    <div class="sm Ineffective">Ineffective: {{ rating.count[3] }} </div>
                    <div class="sm NE">NE: {{ rating.count[4] }} </div>
                  </div>
                </v-col>
                <v-col cols="6" class="" style="">
                  <div v-if="hasData">
                    <bubble-chart aria-hidden="true" :percent="ratingData"
                      class="bubble-chart-wrapper">
                    </bubble-chart>
                    <table class="sr-only">
                      <thead>
                        <tr>
                          <th>Label</th>
                          <th>Count</th>
                          <th>Percent</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(r, i) in ratingData.labels" :key="r">
                          <td>{{ r }}</td>
                          <td>{{ ratingData.count[i] }}</td>
                          <td>{{ ratingData.percent[i] }}%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-else style="text-align:center;width: 100%;">
                    No Data to Display at this Time.
                  </div>
                </v-col>
              </v-row>
            </v-container>
        </v-card-text>
      </v-card>
    </v-col>
    </v-row>
    </v-container>
  </div>
</template>

<script>
// import { VContainer } from 'vuetify/lib';
import BubbleChart from '../BubbleChart.vue';

export default {
  name: 'ERSummativeRatingComponent',
  components: { BubbleChart },
  props: { provider: {}, program: {}, year: {} },
  computed: {
    rating() {
      return this.$store.getters.rating(this.provider, this.program, this.year);
    },
    ratingData() {
      const r = this.rating;
      const newRating = { labels: r.labels };
      newRating.percent = r.percent.map((v) => ((typeof v === 'number') ? v : 0));
      newRating.count = r.count.map((v) => ((typeof v === 'number') ? v : 0));
      return newRating;
    },
    hasData() {
      return this.ratingData.count.filter((v) => v !== 0).length > 0;
    },
  },
};
</script>

<style scoped>
  .border{
      border: solid 1px;
      margin: 10px;
  }
  .rating-wrapper{
    /* height:100%; */
  }
  .sub-text{
    line-height: 1.2;
  }
  .v-card__title{
    font-size: 15px;
    padding-top: 5px;
  }
  .v-card__text{
    padding: 2px;
  }

</style>
