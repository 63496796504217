<template>
  <v-flex>
      <v-card tile flat max-width="100%" v-if="genders !== null">
          <v-card-title class="headline cd-headline">Completers by Gender:</v-card-title>
          <v-card-text>
              <div class="gender">
                    <span v-for="f in female" title="Female"
                    :key="f.Subgroup" :class="f.Subgroup"
                    :style="{width: width(f.Percentage) + '%',
                    maxWidth: maxWidth(f.Percentage), minWidth: minWidth(f.Percentage)}">
                    Female {{ percentOrBlank(f.Percentage) }}%</span>
                  <span v-for="m in male" title="Male"
                   :key="m.Subgroup" :class="m.Subgroup"
                   :style="{width: width(m.Percentage) + '%',
                    maxWidth: maxWidth(m.Percentage), minWidth: minWidth(m.Percentage)}">
                    Male {{ percentOrBlank(m.Percentage) }}%</span>
                  <span
                    v-for="x in nonbinary" title="Non-Binary/Undesignated"
                    :key="x.Subgroup" :class="x.Subgroup +' X'"
                    :style="{display: 'inline-block',
                    width: width(x.Percentage) + '%',
                    maxWidth: maxWidth(x.Percentage), minWidth: minWidth(x.Percentage)}">
                    <div style="display: flex;">
                      <div style="flex: 1;">
                        <span>Non Binary/ <br/>Undesignated</span></div>
                      <div style="flex: 1;padding-top: 10px;padding-left: 10px;">
                        <span>
                          {{ percentOrBlank(x.Percentage) }}%
                        </span>
                      </div>
                    </div>
                  </span>
                  <span v-for="n in not" title="Gender Not Reported"
                   :key="n.Subgroup" :class="n.Subgroup +' N'"
                    :style="{display: 'inline-block',
                    width: width(n.Percentage) + '%',
                    maxWidth: maxWidth(n.Percentage), minWidth: minWidth(n.Percentage)}">
                    <div style="display: flex;">
                      <div style="flex: 1;">
                        <span>Unreported/ <br/>Unknown</span></div>
                      <div style="flex: 1;padding-top: 10px;padding-left: 10px;">
                        <span>
                          {{ percentOrBlank(n.Percentage) }}%
                        </span>
                      </div>
                    </div>
                  </span>
                  <!-- <span v-for="n in not" title="Gender Not Reported"
                   :key="n.Subgroup" :class="n.Subgroup +' N'"
                   :style="{width: width(n.Percentage) + '%',
                    maxWidth: maxWidth(n.Percentage), minWidth: minWidth(n.Percentage)}">
                    Unreported/ {{ percentOrBlank(n.Percentage) }}%<br/>Unknown</span> -->
              </div>
          </v-card-text>
      </v-card>
    <v-simple-table class="d-none d-sr-only">
      <template v-slot:default>
        <thead>
          <tr>
            <th>Gender Sub Group</th>
            <th>Percentage</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="f in female" :key="f.Subgroup">
            <td>Female</td>
            <td>{{percentOrBlank(f.Percentage)}}%</td>
          </tr>
          <tr v-for="m in male" :key="m.Subgroup">
            <td>Male</td>
            <td>{{percentOrBlank(m.Percentage)}}%</td>
          </tr>
          <tr v-for="x in nonbinary" :key="x.Subgroup">
            <td>Non-Binary/Undesignated</td>
            <td>{{percentOrBlank(x.Percentage)}}%</td>
          </tr>
          <tr v-for="n in not" :key="n.Subgroup">
            <td>Gender Not Reported</td>
            <td>{{percentOrBlank(n.Percentage)}}%</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-flex>
</template>

<script>
import NumberFormatMixin from '../../mixins/number-format-mixins';

export default {
  name: 'CDGender',
  mixins: [NumberFormatMixin],
  props: { provider: {}, program: {}, year: {} },
  mounted() {
    console.log('CDGender Mounted');
  },
  computed: {
    genders() {
      if (!this.year) {
        console.error('Year prop is undefined');
        return [];
      }
      return this.$store.getters.gender(this.provider, this.program, this.year);
    },
    male() {
      const m = this.genders;
      let maleData;
      console.log(m);
      // eslint-disable-next-line no-return-assign, no-unused-vars, no-shadow
      return maleData = m.filter((m) => m.Subgroup === 'M');
    },
    female() {
      const f = this.genders;
      let femaleData;
      // eslint-disable-next-line no-return-assign, no-unused-vars, no-shadow
      return femaleData = f.filter((f) => f.Subgroup === 'F');
    },
    nonbinary() {
      const f = this.genders;
      let nonbinaryData;
      // eslint-disable-next-line no-return-assign, no-unused-vars, no-shadow
      return nonbinaryData = f.filter((f) => f.Subgroup === 'X');
    },
    not() {
      const n = this.genders;
      let notData;
      // eslint-disable-next-line no-return-assign, no-unused-vars, no-shadow
      return notData = n.filter((n) => n.Subgroup === 'Gender Not Reported');
    },
  },
  methods: {
    width(Percentage) {
      return Percentage;
    },
    maxWidth(genderPercentage) {
      console.log(this.genders);
      const totalItems = this.genders.length;
      let maxWidthPercentage = 50 / totalItems;
      maxWidthPercentage += genderPercentage;
      const maxWidthString = `${maxWidthPercentage}%`;
      return maxWidthString;
    },
    minWidth(genderPercentage) {
      console.log(this.genders);
      const minWidth = 16;
      const totalItems = this.genders.length;
      const remainingWidth = 100 - (minWidth * totalItems);
      const itemWidth = minWidth + (genderPercentage / 100) * remainingWidth;
      return `${itemWidth}%`;
    },
  },
  data() {
    return {
      styleObject: {
        width: '%',
      },
    };
  },
};
</script>

<style scoped>
  .small {
      max-width: 300px;
      margin:  15px auto;
  }
  .chart-title{
      font-size: 1.4em;

  }
  .centered{
      margin-left: 40%;
      float: left;
      height: 100px;
  }
  .gender{
      height: 42px;
      width: 100%;
      float: left;
  }
  .not-gender{
      float: left;
      width: 23%;
      margin-top: 6px;
      margin-left: 10px;
  }
  .gender, .not-gender {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .F{
      padding: 15px 0;
      background: #696969;
      color: white;
      font-weight: bold;
      border: 1px solid #696969;
      float: left;
      text-align: center;
  }
  .F:before{
      color: white;
      font-weight: bold;
  }
  .M{
      padding: 15px 0;
      background: #ffffff;
      border: 1px solid #6969695d;
      margin: 0;
      font-weight: bold;
      float: left;
      text-align: center;
  }
  .X{
      /* padding: 15px 0; */
      background: #c3bfbf94;
      color: rgba(0, 0, 0, 0.516);
      border: 1px solid #7a757568;
      margin: 0;
      font-weight: bold;
      float: left;
      text-align: center;
      white-space: nowrap; /* Add this line */
      align-content: center;
      text-align: left;
      padding-left: 10px;
  }
  .N{
      /* padding: 15px 0; */
      background: #ffffff;
      border: 1px solid #69696941;
      margin: 0;
      font-weight: bold;
      float: left;
      text-align: center;
      white-space: nowrap; /* Add this line */
      align-content: center;
      text-align: left;
      padding-left: 10px;
  }
  .unknown{
      font-weight: bold;
  }
  .headline{
      color: #114d72;
  }
</style>
<style>
</style>
